import amplitude from "amplitude-js"
import type { AmplitudeClient } from "amplitude-js"

class Amplitude {
  constructor() {
    this.instance = amplitude.getInstance()

    this.instance.init(
      process.env.REACT_APP_AMPLITUDE_API_KEY || "",
      undefined,
      {
        includeUtm: true,
        includeReferrer: true,
        includeGclid: true,
        saveParamsReferrerOncePerSession: false,
        apiEndpoint: "amplitude.soulplatform.com",
      },
    )
  }

  instance: AmplitudeClient | null = null
}

export const amplitudeClient = new Amplitude()

export const sendAmplitudeEvent = (
  eventType: string,
  eventProperties?: Record<
    string,
    string | number | undefined | string[] | number[] | null
  >,
  callback?: (responseCode: number, responseBody: Object) => void,
) => {
  amplitude
    .getInstance()
    .logEvent(
      eventType,
      {
        ...eventProperties,
        onboarding_host: window.location.hostname,
        utm_medium: new URLSearchParams(window.location.search).get("utm_medium"),
      },
      callback,
    )
}

export const setAmplitudeUserId = (id: string | null) => {
  amplitude.getInstance().setUserId(id)
}

export const setAmplitudeUserProperties = (
  properties: Record<string, string>,
) => {
  amplitude.getInstance().setUserProperties(properties)
}
